export const rounded = {
  1: '3.2px',
  2: '6.4px',
  3: '9.6px',
  4: '12.8px',
  5: '16px',
  6: '20px',
  10: '32px',
  25: '25%',
  50: '50%',
  75: '75%',
  100: '100%',
}

import React from 'react'
import SearchCorresponding from './searchCorresponding/_index'

const AbraSuaConta = () => {
  return (
    <section id='correspondentes' className='py-md-2 py-lg-4'>
      <div className='container py-5'>
        <div className='row align-items-center justify-content-between'>
          <div className='col-12'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 text-grayscale--500 fw-600 text-md-center mb-4'>
              Confira o correspondente mais próximo de você!
            </h2>
            <p className='fs-16 fs-lg-18 lh-20 text-grayscale--500 fw-400 text-md-center'>
              Preencha todos os campos abaixo e localize nossos correspondentes.
            </p>
          </div>
        </div>
        <SearchCorresponding />
      </div>
    </section>
  )
}

export default AbraSuaConta

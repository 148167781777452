import styled from 'styled-components'
import { gray, orange, grayscale } from '../../../../../styles/colors'
import { rounded } from '../../../../../styles/rounded'

export const Container = styled.div`
  label {
    color: #6A6C72;
  }

  span.select {
    background: ${gray['400']};
    border-radius: ${rounded['1']};
    display: block;
    position: relative;
    outline: none;
    overflow: hidden;
    width: 100%;
    height: 48px;

    &:after {
      content: "";
      border-color: transparent transparent  ${orange.extra} ${orange.extra};
      border-style: solid;
      border-width: 2px;
      height: 9px;
      margin-top: -7px;
      padding: 0;
      pointer-events: none;
      position: absolute;
      right: 14px;
      top: 50%;
      transform: rotate(315deg);
      transition: all .2s ease-in-out;
      width: 9px;
    }

    select {
      appearance: none;
      background: ${gray['400']};
      border: 0;
      border-radius: ${rounded['1']};
      color: #B6B7BB;
      cursor: pointer;
      font-size: 14px;
      padding: 8.5px;
      width: 100%;
      height: 48px;
      font-weight: 700;
    }
  }

  button.bg-orange--linear {
    background: linear-gradient(7.83deg, ${orange.dark} 0%, ${orange.base} 100%);
    border-radius: ${rounded['1']};
    height: 44px;
    padding: 0 10px;
    max-width: 100%;
    text-transform: none;

    &:disabled {
      background: ${gray['200']};
      color: ${grayscale['300']}!important;
      text-transform: none;
    }
  }
`
import React from 'react'
import pageContext from './pageContext.json'
import { Wrapper } from './style'
import Layout from 'src/layouts/BaseLayout'

import Hero from './sections/hero/_index'
import Correspondente from './sections/correspondente/_index'

const Credito = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <Correspondente />
      </Layout>
    </Wrapper>
  )
}

export default Credito

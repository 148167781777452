import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'

export const Section = styled.section`
  height: 830px;
  background-image: url("https://central-imagens.bancointer.com.br/images-without-small-versions/bg-sm-cb/image.webp");
  background-repeat: no-repeat;
  background-size: contain;
  
  @media ${device.tablet} {
    height: 545px;
    background-image: url("https://central-imagens.bancointer.com.br/images-without-small-versions/bg-md-cb/image.webp");
    background-position: top left;
  }

  @media ${device.desktopLG} {
    height: 598px;
    background-image: url("https://central-imagens.bancointer.com.br/images-without-small-versions/bg-lg-cb/image.webp");
  }

  @media ${device.desktopXL} {
    height: 740px;
    background-image: url("https://central-imagens.bancointer.com.br/images-without-small-versions/bg-xl-cb/image.webp");
  }

  button {
    &:hover {
      color: ${orange.extra};
    }
    width: 100%;
    border: none;

    @media ${device.desktopLG} {
      width: 456px;
    }
  }
`

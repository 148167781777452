import React from 'react';

interface LabelProps {
  text: string;
  htmlFor: string;
}

const Label = ({ text, htmlFor }: LabelProps) => {
  return (
    <label
      className='fs-14 lh-15 fw-600 text-grayscale--400 d-block'
      htmlFor={htmlFor}
    >
      {text}
    </label>)
}

export default Label

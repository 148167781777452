import React from 'react'
import { Result } from './style'

import { CorrespondingProps } from '../types'

const ResultSearch = ({ result, corresponding }: CorrespondingProps) => {
  return (
    <Result className='px-4 py-4'>
      <p className='fs-16 fw-600 lh-20 text-grayscale--500 mb-1'>Correspondente</p>
      <p className='fs-14 fw-400 lh-20 text-grayscale--500'>
        { result ? corresponding?.name : '...' }
      </p>
      <p className='fs-16 fw-600 lh-20 text-grayscale--500 mt-4 mb-1'>CNPJ</p>
      <p className='fs-14 fw-400 lh-20 text-grayscale--500'>
        { result ? corresponding?.cnpj : '...' }
      </p>
      <p className='fs-16 fw-600 lh-20 text-grayscale--500 mt-4 mb-1'>Endereço</p>
      <p className='fs-14 fw-400 lh-20 text-grayscale--500'>
        { result ? corresponding?.address : '...' }
      </p>
      <p className='fs-16 fw-600 lh-20 text-grayscale--500 mt-4 mb-1'>Contratante</p>
      <p className='fs-14 fw-400 lh-20 text-grayscale--500 mb-0'>
        { result ? corresponding?.contractor : '...' }
      </p>
    </Result>
  )
}

export default ResultSearch;

import React from 'react'
import { Section } from './style'

import ScrollTo from 'src/components/ScrollTo'

const Header = () => {
  return (
    <Section className='bg-orange--extra d-flex align-items-end align-items-md-center pb-3'>
      <div className='container pb-3 pb-md-0'>
        <div className='row'>
          <div className='col-12 col-md-6 offset-md-6 col-xl-5 offset-xl-7'>
            <h2 className='fs-24 fs-md-24 fs-lg-40 lh-30 lh-lg-40 lh-xl-50 text-grayscale--500 fw-600 text-white mb-3'>Correspondentes do Inter no País</h2>
            <p className='fs-16 fs-lg-20 fw-600 lh-20 lh-xl-22 text-white'>Encontre os nossos correspondentes bancários de crédito imobiliário no País</p>
            <p className='fs-14 lh-16 fs-xl-18 lh-20 lh-xl-22 text-white'>Nosso compromisso no Inter é oferecer produtos de forma simples e segura para você.</p>
            <p className='fs-14 lh-16 fs-xl-18 lh-20 lh-xl-22 text-white'>
              Os correspondentes bancários são empresas contratadas para prestar serviços de atendimento ao cliente.
              Caso se sinta desconfortável com a conduta de algum Correspondente Bancário que oferta produtos em
              nome do Inter, entre em contato conosco. Para isso, tenha em mãos o telefone, CNPJ ou nome comercial
              do Correspondente Bancário.
            </p>
            <ScrollTo
              to='#correspondentes'
              section='dobra_01'
              sectionName='Correspondentes do Inter no País'
              elementName='Mostrar contatos'
            >
              <button
                className='btn--lg btn-white text-orange--extra rounded-3 fs-14 fw-600 text-none mt-3'
              > Mostrar contatos
              </button>
            </ScrollTo>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Header
